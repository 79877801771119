import * as React from 'react';
import {isIOS, isAndroid} from 'react-device-detect';

  export default class AppRedirectComponent extends React.Component {

    render() {
        (function() {
            var app = {
              launchApp: function() {
                if (isIOS) {
                    window.location.replace("xsats://");
                } else if (isAndroid) {
                    window.location.replace("market://details?gotohome=com.xsats.xsatsag");
                }
                this.timer = setTimeout(this.openWebApp, 1000);
              },
          
              openWebApp: function() {
                if (isIOS) {
                    window.location.replace("https://apps.apple.com/gb/app/xsats/id1525551447");
                } else if (isAndroid) {
                    window.location.replace("https://play.google.com/store/apps/details?id=com.xsats.xsatsag");
                } else {
                    window.location.replace("/");
                }
              }
            };
          
            app.launchApp();
          })();
      return (
        null
      )
    }
  }