import React from 'react';
import PrivacyPolicy from '../components/privacy/PrivacyPolicy'
// import sections

const Privacy = () => {
  return (
    <>
      <PrivacyPolicy/>
    </>
  );
}

export default Privacy;