import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

function contentToHtml(text) {
  return text
    .split('\n\n')
    .map(paragraph => `<p>${paragraph}</p>`)
    .join('')
}

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const PrivacyPolicy = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <p className='tw-400'>Effective date: 6th September, 2019</p>
            <h1 className="mt-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
                <span className="fw-400">XSATS AG PRIVACY POLICY</span>.
            </h1>
            <div className="container">
              <p className="m-0 mb-32 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span className="text-color-light fw-400">XSATS AG (“us” , “we” , or “our” ) operates the https://xsats.com website (hereinafter referred to as the “Service” ).<br/><br/>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data. <br/><br/>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms & Conditions.​</span> 
              </p>
            </div>
          </div>
          <div>
            <div className="container">
            <div className="reveal-from-bottom  ta-l" data-reveal-delay="600">
            <h2 className="m-0 mb-12 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">1. Definitions</span> 
              </h2>
              </div>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                {/* <span className="text-color-light fw-400"><strong>Service:</strong> Service is the https://www.xsats.com website operated by XSATS AG <br/><br/><strong>Personal Data:</strong> Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).  <br/><br/><strong>Usage Data:</strong> Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).  <br/><br/><strong>Cookies:</strong> Cookies are small files stored on your device (computer or mobile device).  <br/><br/><strong>Data Controller:</strong> Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.  <br/><br/><strong>Data Processors (or Service Providers):</strong> Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.  <br/><br/><strong>Data Subject (or User):</strong> Data Subject is any living individual who is using our Service and is the subject of Personal Data.</span>  */}
                <span class='col1'><strong>Service:</strong></span>
                <span class='col2'>Service is the https://www.xsats.com website operated by XSATS AG</span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                {/* <span className="text-color-light fw-400"> r/><strong><br/><br/><strong>Data Subject (or User):</strong> Data Subject is any living individual who is using our Service and is the subject of Personal Data.</span>  */}
                <span class='col1'><strong>Personal Data:</strong></span>
                <span class='col2'>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong>Usage Data:</strong></span>
                <span class='col2'>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong>Cookies:</strong></span>
                <span class='col2'>Cookies are small files stored on your device (computer or mobile device).</span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong>Data Controller:</strong></span>
                <span class='col2'>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data. </span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong>Data Processors (or Service Providers):</strong></span>
                <span class='col2'>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.  </span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong></strong></span>
                <span class='col2'></span>
              </p>
              <p className="m-0 mb-8 reveal-from-bottom  ta-l" data-reveal-delay="400">
                <span class='col1'><strong></strong></span>
                <span class='col2'></span>
              </p>
            <div className="reveal-from-bottom  ta-l" data-reveal-delay="600">
              </div>
            </div>
          </div>
          <h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">2. Information Collection and Use</span> 
              </h2>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We collect several different types of information for various purposes to provide and improve our Service to you.</div>


<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(a) Personal Data</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Email address</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Mobile Phone Number</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">First name and last name</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">IP Address of Registration</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">IBAN Account Number and Account Holder</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Customer bitcoin address</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Cookies and Usage Data</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">In certain cases, XSATS will ask its customer to provide additional documentation, in light of our KYC/AML processes. Identity verification information may include, but is not limited to:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">An identification document (i.e. a valid passport, valid European ID card or a valid European driving license)</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Proof of residence (i.e. utility bill, phone bill, or bank account statement, not older than three months)</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Proof of “Source of funds”</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(b) Usage Data</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may also collect information on how the Service is accessed and used (Usage Data”). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(c) Tracking and Cookies Data</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">3. Use of Data</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG uses the collected data for various purposes:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To provide and maintain our Service</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To notify you about changes to our Service</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To provide customer support</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To gather analysis or valuable information so that we can improve our Service</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To monitor the usage of our Service</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To detect, prevent and address technical issues, fraud, and abuse</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">4. Legal Basis for Processing Personal Data (under GDPR)</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">If you are from the European Economic Area (EEA), XSATS AG legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG may process your Personal Data because:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We need to perform a contract with you</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">You have given us permission to do so</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The processing is in our legitimate interests and it is not overridden by your rights</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To comply with the law</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">5. Retention of Data</span> 
              </h2>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">6. Transfer of Data</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">If you are located outside the UK and choose to provide information to us, please note that we transfer the data, including Personal Data, to the UK and process it there.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">7. Disclosure of Data</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(a) Disclosure for Law Enforcement</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Under certain circumstances, XSATS AG may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). </div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(b) Legal Requirements</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">XSATS AG may disclose your Personal Data in the good faith belief that such action is necessary to:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To comply with a legal obligation</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To protect and defend the rights or property of XSATS AG</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To prevent or investigate possible wrongdoing in connection with the Service</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To protect the personal safety of users of the Service or the public</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">To protect against legal liability</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">8. Security of Data</span> 
              </h2>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The security of your data is important to us but please note that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</div>


<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">9. Your Data Protection Rights  (under GDPR)</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">If you are a resident of the European Economic Area (EEA), you have certain data protection rights. XSATS AG aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">In certain circumstances, you have the following data protection rights:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within the settings area. If you are unable to perform these actions yourself, please contact us to assist you.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right to object. You have the right to object to our processing of your Personal Data.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right of restriction. You have the right to request that we restrict the processing of your personal information.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">The right to withdraw consent. You also have the right to withdraw your consent at any time where XSATS AG relied on your consent to process your personal information.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Please note that we may ask you to verify your identity before responding to such requests.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</div>


<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">10. Service Providers</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</div>


<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(a) Analytics</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may use third-party Service Providers to monitor and analyse the use of our Service.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Google Analytics</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">For more information on the privacy practices of Google, please visit Google Privacy & Terms web page.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">(b) Operational</div>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may use third-party Service Providers to provide the customer a better user experience or enhance our Service.​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">SendGrid: for reliable email delivery, XSATS uses the SendGrid Inc. platform. SendGrid, Inc. (SendGrid) serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send emails by XSATS. For more information on the privacy practices of SendGrid, please visit SendGrid Privacy & Terms.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">mailgun: for reliable email delivery, XSATS uses the mailgun platform. mailgun serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send emails by XSATS. For more information on the privacy practices of mailgun, please visit mailgun Privacy & Terms. </div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Twilio: for reliable SMS delivery, XSATS uses the Twilio Ireland Limited platform. Twilio Ireland Limited (Twilio) serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send SMS messages by XSATS. For more information on the privacy practices of Twilio, please visit Twilio Privacy & Terms.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Google Auth: for reliable and secure two-factor authentication (2FA) enabling enhanced protection and security of user's interactions with the service, XSATS uses the Google Authenticator API. For more information on the privacy practices of Google Authenticator, please visit Google Accounts Authentication API Terms and Conditions.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">11. Links to third Party Websites</span> 
              </h2>
<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Our Service may contain links to third party websites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">12. Children's Privacy</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">Our Service does not address anyone under the age of 18 (“Children”).</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">13. Changes to the Privacy Policy</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">​</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</div>


<h2 className="m-0 mb-12 mt-24 reveal-from-bottom  ta-l" data-reveal-delay="200">
                <span className="text-color-light fw-400">14. Contact Us</span> 
              </h2>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">If you have any questions about this Privacy Policy, please contact us:</div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">By email: <a href="mailto:support@xsats.com<">support@xsats.com</a></div>

<div className="ta-l reveal-from-bottom" data-reveal-delay="400">By visiting the support page on our website</div>
        </div>
      </div>
    </section>
  );
}

PrivacyPolicy.propTypes = propTypes;
PrivacyPolicy.defaultProps = defaultProps;

export default PrivacyPolicy;


// Effective date: 6th September, 2019

// ​

// XSATS AG (“us” , “we” , or “our” ) operates the https://www.xsats.com website (hereinafter referred to as the “Service” ).

// This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.

// ​

// We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms & Conditions.

// ​

// 1. Definitions
// Service: Service is the https://www.xsats.com website operated by XSATS AG <br/><br/>Personal Data: Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).  <br/><br/>Usage Data: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).  <br/><br/>Cookies: Cookies are small files stored on your device (computer or mobile device).  <br/><br/>Data Controller: Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.  <br/><br/>Data Processors (or Service Providers): Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.  <br/><br/>Data Subject (or User): Data Subject is any living individual who is using our Service and is the subject of Personal Data.  <br/><br/>

// ​

// let text = "2. Information Collection and Use
// We collect several different types of information for various purposes to provide and improve our Service to you.

// ​

// (a) Personal Data
// While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:

// Email address

// Mobile Phone Number

// First name and last name

// IP Address of Registration

// IBAN Account Number and Account Holder

// Customer bitcoin address

// Cookies and Usage Data

// ​

// In certain cases, XSATS will ask its customer to provide additional documentation, in light of our KYC/AML processes. Identity verification information may include, but is not limited to:

// An identification document (i.e. a valid passport, valid European ID card or a valid European driving license)

// Proof of residence (i.e. utility bill, phone bill, or bank account statement, not older than three months)

// Proof of “Source of funds”

// ​

// (b) Usage Data
// We may also collect information on how the Service is accessed and used (Usage Data”). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.

// ​

// (c) Tracking and Cookies Data
// We may use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.


// 3. Use of Data

// XSATS AG uses the collected data for various purposes:

// To provide and maintain our Service

// To notify you about changes to our Service

// To provide customer support

// To gather analysis or valuable information so that we can improve our Service

// To monitor the usage of our Service

// To detect, prevent and address technical issues, fraud, and abuse

// ​

// 4. Legal Basis for Processing Personal Data (under GDPR)
// If you are from the European Economic Area (EEA), XSATS AG legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.

// XSATS AG may process your Personal Data because:

// We need to perform a contract with you

// You have given us permission to do so

// The processing is in our legitimate interests and it is not overridden by your rights

// To comply with the law

// ​

// 5. Retention of Data
// XSATS AG will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.

// XSATS AG will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.


// 6. Transfer of Data

// Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.

// If you are located outside the UK and choose to provide information to us, please note that we transfer the data, including Personal Data, to the UK and process it there.

// Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

// XSATS AG will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.


// 7. Disclosure of Data

// (a) Disclosure for Law Enforcement
// Under certain circumstances, XSATS AG may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). 

// ​

// (b) Legal Requirements
// XSATS AG may disclose your Personal Data in the good faith belief that such action is necessary to:

// To comply with a legal obligation

// To protect and defend the rights or property of XSATS AG

// To prevent or investigate possible wrongdoing in connection with the Service

// To protect the personal safety of users of the Service or the public

// To protect against legal liability

// ​

// 8. Security of Data
// The security of your data is important to us but please note that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.


// 9. Your Data Protection Rights  (under GDPR)

// If you are a resident of the European Economic Area (EEA), you have certain data protection rights. XSATS AG aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.

// If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.

// ​

// In certain circumstances, you have the following data protection rights:

// The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.

// The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.

// The right to object. You have the right to object to our processing of your Personal Data.

// The right of restriction. You have the right to request that we restrict the processing of your personal information.

// The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.

// The right to withdraw consent. You also have the right to withdraw your consent at any time where XSATS AG relied on your consent to process your personal information.

// ​

// Please note that we may ask you to verify your identity before responding to such requests.

// You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).


// 10. Service Providers

// We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.

// These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.


// (a) Analytics

// We may use third-party Service Providers to monitor and analyse the use of our Service.

// Google Analytics

// Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.

// You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.

// For more information on the privacy practices of Google, please visit Google Privacy & Terms web page.

// ​

// (b) Operational
// We may use third-party Service Providers to provide the customer a better user experience or enhance our Service.​

// SendGrid: for reliable email delivery, XSATS uses the SendGrid Inc. platform. SendGrid, Inc. (SendGrid) serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send emails by XSATS. For more information on the privacy practices of SendGrid, please visit SendGrid Privacy & Terms.

// mailgun: for reliable email delivery, XSATS uses the mailgun platform. mailgun serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send emails by XSATS. For more information on the privacy practices of mailgun, please visit mailgun Privacy & Terms. 

// Twilio: for reliable SMS delivery, XSATS uses the Twilio Ireland Limited platform. Twilio Ireland Limited (Twilio) serves as a Service Provider of certain Personal Information as it collects, processes and stores that information to reliably send SMS messages by XSATS. For more information on the privacy practices of Twilio, please visit Twilio Privacy & Terms.

// Google Auth: for reliable and secure two-factor authentication (2FA) enabling enhanced protection and security of users, XSATS uses the Google Authenticator API. For more information on the privacy practices of Google Authenticator, please visit Google Accounts Authentication API Terms and Conditions.

// ​

// 11. Links to third Party Websites
// Our Service may contain links to third party websites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.

// We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.


// 12. Children's Privacy

// Our Service does not address anyone under the age of 18 (“Children”).

// We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.

// ​

// 13. Changes to the Privacy Policy

// We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

// We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.

// ​

// You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.


// 14. Contact Us

// If you have any questions about this Privacy Policy, please contact us:

// By email: xsats@protonmail.com

// By visiting the support page on our website"