import React, { Component } from 'react';
import ColdsatsHero from './ColdsatsHero.js'

export default class ColdsatsComponent extends Component {
    render() {
      return (
        <div className="container mt-96">
          <div className="mb-32">
            <ColdsatsHero />
          </div>
        </div>
      )
    }
  }