import React from "react";
import PriceTicker from "../ticker/PriceTicker";
import "./Footer.css";
import logo from "../../assets/images/xsats-logo-tagline-save-and-secure-bitcoin.png";
import appStore from "../../assets/images/apple-app-store-badge.png";
import playStore from "../../assets/images/google-play-badge.png";
import twitter from "../../assets/images/social-icons/twitter-icon.png";
import facebook from "../../assets/images/social-icons/facebook-icon.png";
import telegram from "../../assets/images/social-icons/telegram-icon.png";
import linkedin from "../../assets/images/social-icons/linkedin-icon.png";
import instagram from "../../assets/images/social-icons/instagram-icon.png";
import youtube from "../../assets/images/social-icons/youtube-orig-icon.png";
import github from "../../assets/images/social-icons/github-icon.png";

import whitepaper from "../../assets/documents/bitcoin-whitepaper-satoshi-nakamoto.pdf"
import terms from "../../assets/documents/xsats-AG-Terms-and-Conditions.pdf";


const Footer = () => {
    return (
    <footer class="c-public-footer">
  <div class="c-public-footer__inner">
    <div class="c-public-footer__column c-public-footer__left">
      <div class="c-public-footer__info">
        <div class="c-public-footer__logo h-mb-2">
          <a href="https://xsats.com" target="_blank" rel = "noopener noreferrer">
            <img src={logo} alt="xsats logo"/>
          </a>
        </div>
        <div class="c-public-footer__copyright h-text-sm h-text-gray-dark">
          <p>© 2022 XSATS AG</p>
        </div>
      </div>
      {/* <div class="c-public-footer__app">
        <a class="c-public-footer__app-icon" href="https://apps.apple.com/gb/app/xsats/id1525551447" target="_blank" rel = "noopener noreferrer">
          <img src={appStore} alt="xsats android app" />
        </a>
        <a class="c-public-footer__app-icon" href="https://play.google.com/store/apps/details?id=com.xsats.xsatsag" target="_blank" rel = "noopener noreferrer">
          <img style={{width: "40%", marginLeft: 10, marginTop: 5, verticalAlign: "top"  }}  src={playStore} alt="xsats iOS app" />
        </a>
			</div> */}
      <PriceTicker/>
    </div>
    <div class="c-public-footer__column c-public-footer__grid-wrapper">
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Product</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
					{/* <a class="c-link" style={{fontSize: 20}} href="/x/">Sign Up</a> */}
          {/* <a class="c-link" style={{fontSize: 20}} href="/app">Mobile App</a> */}
          <a class="c-link" style={{fontSize: 20}} href="mailto:support@xsats.com">Contact Support</a>
          {/* <a class="c-link" style={{fontSize: 20}} href="/buy-bitcoin">Buy Bitcoin</a> */}
          {/* <a class="c-link" style={{fontSize: 20}} href="/security">Security</a> */}
          {/* <a class="c-link" style={{fontSize: 20}} href="/bitcoin-price">Bitcoin Price</a> */}
				</div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Company</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
					<a class="c-link" style={{fontSize: 20}} href="/about">About</a>
          <a class="c-link" style={{fontSize: 20}} href="https://xsats.com/faq">FAQ</a>
          <a class="c-link" style={{fontSize: 20}} href="https://blog.xsats.com">Blog</a>
          {/* <a class="c-link" style={{fontSize: 20}} href="" target="_blank" rel = "noopener noreferrer">Careers</a> */}
          {/* <a class="c-link" style={{fontSize: 20}} href="">Newsroom</a> */}
				</div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Social</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
        <a class="c-link" style={{fontSize: 20}} href="https://twitter.com/xsatoshis" target="_blank" rel = "noopener noreferrer">
            <img class="c-public-footer__social-icon" alt="Twitter icon" src={twitter} target="_blank" />
          <p style={{display: "inline",}}>Twitter</p>
        </a>
        <a class="c-link" style={{fontSize: 20}} href="https://t.me/xsatoshis" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Twitter icon" src={telegram} target="_blank" />
          <p style={{display: "inline"}}>Telegram</p>
        </a>
        <a class="c-link" style={{fontSize: 20}} href="https://www.linkedin.com/company/xsats" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="LinkedIn icon" src={linkedin} />
          <p style={{display: "inline"}}>LinkedIn</p>
        </a>
        <a class="c-link" style={{fontSize: 20}} href="https://www.facebook.com/xsats" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Facebook icon" src={facebook} />
          <p style={{display: "inline"}}>Facebook</p>
        </a>
        </div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 style={{color: "#000"}} >.</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
        <a class="c-link" style={{fontSize: 20}} href="https://www.instagram.com/xsatoshis" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Instagram icon" src={instagram} />
          <p style={{display: "inline"}}>Instagram</p>
        </a>
        <a class="c-link" style={{fontSize: 20}} href="https://www.youtube.com/channel/UC8YHMMidbNVKXeRaoEk9r6g" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="YouTube icon" src={youtube} />
          <p style={{display: "inline"}}>YouTube</p>
        </a>
        <a class="c-link" style={{fontSize: 20}} href="https://www.github.com/xsats" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="GitHub icon" src={github} />
          <p style={{display: "inline"}} >GitHub</p>
        </a>
        </div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Learn</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
          <a className="c-link" style={{fontSize: 16}}  href={whitepaper}>Bitcoin Whitepaper</a>
          <a class="c-link" style={{fontSize: 16}}  href="https://bitcoiner.guide/qna/">Bitcoin QnA</a>
          <a class="c-link" style={{fontSize: 16}} href="https://bitcoin-resources.com/">Bitcoin Resources</a>
          <a class="c-link" style={{fontSize: 16}}  href="https://bitcoin-only.com">Bitcoin Only</a>
          <a class="c-link" style={{fontSize: 16}}  href="https://www.lopp.net/bitcoin-information.html">Lopp's Guide</a>
        </div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 style={{color: "#000"}} >.</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
        <a class="c-link" style={{fontSize: 16}}  href="https://nakamotoinstitute.org/">Nakamoto Institute</a>
          <a class="c-link" style={{fontSize: 16}}  href="https://bitcoinmythology.org/">Bitcoin Mythology</a>
          <a class="c-link" style={{fontSize: 16}} href="https://unchained-capital.com/blog/dollar-crisis-to-bitcoin/">Gradually {'>'} Suddenly</a>
          <a class="c-link" style={{fontSize: 16}}  href="https://github.com/BlockchainCommons/Learning-Bitcoin-from-the-Command-Line/blob/master/01_0_Introduction.md">Learn Bitcoin from CL</a>
        </div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Stay Updated</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
          <a class="c-link" style={{fontSize: 16}} href="https://jimmysong.substack.com/">Bitcoin Tech Talk</a>
          <a class="c-link" style={{fontSize: 16}} href="https://bitcoinops.org/">Bitcoin Optech</a>
          <a class="c-link" style={{fontSize: 16}} href="https://tftc.io/martys-bent/">Marty's Bent</a>
        </div>
      </div>
      <div class="c-public-footer__grid-item">
        <div class="c-public-footer__grid-item-title">
          <h4 class="c-public-footer__header">Legal</h4>
        </div>
        <div class="c-public-footer__grid-item-list">
          {/* <a class="c-link" style={{fontSize: 16}} style={{fontSize: 20}} href="/legal/licenses">Licenses</a> */}
          <a class="c-link" style={{fontSize: 16}} href="https://xsats.com/privacy">Privacy Policy</a>
          <a class="c-link" style={{fontSize: 16}} href={terms}>Terms of Service</a>
        </div>
      </div>
    </div>
  </div>
</footer>)}

export default Footer;