import React, { Component } from 'react';
import Faq from 'react-faq-component';

const faqData = {
  title: "General FAQ",
  rows: [
    {
      title: "How does xsats work?",
      content: <p>Once you've provided your email address, you're just a few simple steps away from receiving bitcoin in your own wallet:<br/><br/>
      1) Create a bitcoin wallet (or open up your existing wallet)<br/>
      2) Generate and submit an address, where your bitcoin will be sent<br/>
      3) Validate your ownership of the address<br/>
      4) Send a transfer using your personal unique payment reference<br/><br/>
      Usually within a few minutes of sending your payment (for supported GBP Faster Payments/SEPA instant payments), your bitcoin purchase will be processed and queued in a batch ready for ssending to your wallet</p>
    },
    // {
    //   title: "Can I buy bitcoin as a company with xsats?",
    //   content: <p>Certainly! Directors and owners of companies looking to purchase bitcoin on behalf of their company can do so.<br/><br/> 
    //   As for individuals, we can facilitate both large individual transactions, or a streamlined way to automatically accumulate bitcoin. Please <a href="mailto:hello@xsats.com"><strong>reach out</strong></a> and we'll be very happy to guide you through the process of creating an account and making a purchase for your company.</p>
    // },
    {
      title: "I sent a payment, what next?",
      content: <p>When you make a bitcoin purchase through xsats, your order will progress through the following steps:<br/><br/>
      1) <strong>Pending</strong> - Your payment is yet to arrive/clear.<br/>
      2) <strong>Processing</strong> - Your payment has been received and your order is being processed.<br/>
      3) <strong>Queued</strong> - Your order has been processed, bitcoin purchase price locked in, and sats queued in a batch, pending broadcasting.<br/>
      4) <strong>Broadcast</strong> - Your bitcoin is on its way to your wallet, having been broadcast in a transaction on the bitcoin network. Your wallet will now detect the inbound bitcoin.<br/>
      5) <strong>Confirmed</strong> - The bitcoin transaction has been mined in a block and immutably written into the bitcoin timechain. Your new sats have joined your hoard.<br/>
      </p>
    },
    {
      title: "How long until I receive my bitcoin?",
      content: <p>There are a number of different factors that will determine how long it will be before you receive your bitcoin. Some are within our control, and some not so much. These factors include:<br/><br/>

      1) The time it takes for us to receive your payment<br/>
      2) The duration of time your sats are queued in a pending batch<br/>
      3) The time it takes for the bitcoin transaction to your wallet to be accepted and confirmed in the bitcoin blockchain<br/><br/>
      
      If you pay using £ bank transfer, the chances are your payment will be made using UK FasterPayments. Although the vast majority of such payments arrive almost immediately, sometimes these payments can take upto 2hrs to arrive.<br/><br/>
      If it's your first time using xsats, or you sent payment from a different bank account compared to usual, it may take slightly longer for your payment to clear our bank's checks.
      Once your payment has cleared, your order will be processed and your bitcoin queued in a batch. <br/><br/>
      While queued, your bitcoin won't yet be visible in your wallet, since the batch has not been broadcast yet. We batch orders so as to save on bitcoin network fees and in turn keep our fees low. In general, batches will be broadcast every hour or so during extended trading hours (8:30am -11pm), although the frequency at which batches are broadcast may reduce during times where the Bitcoin network fees are high. Note that orders are still processed 24/7, so you can still stack sats at 4am. For security reasons at present, we simply don't submit bitcoin withdrawals overnight.<br/><br/>
      When the batch with your bitcoin is broadcast, your bitcion will now appear within your wallet as a pending transaction shortly after. However, you don't 'fully receive’ your bitcoin until a number of confirmations have occurred on the bitcoin network. The general consensus is that a transaction is regarded as ‘complete’ once it has undergone at least 5/6 confirmations, meaning 5/6 blocks of transactions have been tagged on the end of the block containing your bitcoin. On average, a new block is added every 10mins or so, but depending on bitcoin network conditions at the time and the fees associated with the transaction, it may take longer.
      </p>
    },
    {
      title: "Can I stack sats 24/7?",
      content: <p>Absolutely!<br/> Provided you don't make a mistake when you send your payment (double check that your payment reference and the recipient are accurate!), and your payment is within your limits, bitcoin orders will be processed night and day, meaning you can always stack sats at your desired price.<br/><br/>
      Please note: although your purchase will be automatically processed around the clock (and your bitcoin price locked in), for security reasons batches of withdrawals will only be processed between approx 8:30am and 10:30pm GMT (7 days/week).</p>
    },
    {
      title: "What are 'sats'?",
      content: <p>1 bitcoin can be divided into 100 000 000 satoshis or 'sats' for short. Regularly saving bitcoin is often (rather affectionately) termed 'stacking sats'.</p>
    },
    {
      title: "What is the minimum order size?",
      content: <p>Currently, the smallest payment that we can accept is £40.</p>
    },
    {
      title: "What are the limits?",
      content: <p>After signing up, you can immediately buy upto CHF 1000 (€900, £750) per day and CHF 20k (€18k, £15k) per month.</p>
    },
    {
      title: "Which countries are supported?",
      content: <p>xsats is available in all countries in Europe, but it's <strong>not</strong> possible to use xsats to buy bitcoin with a bank account registered in the following high-risk countries, due to their individual additional KYC/AML requirements:<br/><br/>
      • Albania<br/>
      • Andorra<br/>
      • Belarus<br/>
      • Bosnia and Herzegovina<br/>
      • Channel Islands<br/>
      • Faroe Islands<br/>
      • Gibraltar<br/>
      • Iceland<br/>
      • Ireland<br/>
      • Isle of Man<br/>
      • Malta<br/>
      • Moldova<br/>
      • Monaco<br/>
      • Montenegro<br/>
      • Russia<br/>
      • Serbia<br/>
      • Turkey<br/>
      • Ukraine<br/></p>
    },
    {
      title: "Why are bitcoin transactions batched, and not sent immediately?",
      content: <p>We batch bitcoin transactions on the network in order to use the network more efficiently and thus save on network fees (the fees paid to bitcoin miners, enticing them to confirm our Bitcoin transactions) and help you get as many sats for your £ as possible.<br/><br/>
      During times when mempools are brimming with transactions, the frequency at which batches are broadcast may be reduced slightly (from the default ~1hr during extended working hours of ~8:30am - 11pm) in order to ensure timely confirmation of your incoming bitcoin.</p>
    },
    {
      title: "Can I receive my bitcoin via the lightning network?",
      content: <p>The lightning network is a non-custodial peer-to-peer second-layer protocol that allows you to send bitcoin instantly for almost zero fees, without needing to trust third parties, using so called 'payment channels'. Lightning shares all of the great properties of bitcoin, but it's uniquely turbocharged for payments!<br/><br/> 
      Aside from being awesome for bitcoin payments, it's also a great way to stack smaller amounts of sats and spread your DCA over hours, minutes or even seconds!<br/><br/>
      We love the lightning network and we're currently actively testing Lightning support for xsats, set to be released by the end of Feb 2021. This will enable another stacking method, particularly well suited to smaller and/or urgent purchases.</p>
    },
    {
      title: "I've bought some bitcoin. What next?",
      content: <p>“An investment in knowledge pays the best interest.”<br/>
      - Benjamin Franklin<br/><br/>
      Take heed of Benjamin's wise words and invest in knowledge. The study of bitcoin is particularly fruitful given the amalgamation of concepts and mechanisms that together enable it to function. It's particularly important to learn how to use and store bitcoin privately and securely. <a href="https://www.bitcoinqna.com/"><strong>Here</strong></a> is a great place to learn more.</p>
    },
    // {
    //   title: "Why 'xsats'?",
    //   content: "extra sats, exchange sats, extreme sats, Exploration of Sats Technologies Corporation"
    // },
    // {
    //   title: "Is ?",
    //   content: "extra sats, exchange sats, extreme sats, Exploration of Sats Technologies Corporation"
    // },
    {
      title: "Can I sell my bitcoin with xsats?",
      content: "No. Currently it's only possible to purchase bitcoin via the xsats platform. Friends don't let friends sell their bitcoin. (Jokes aside, this is something we plan to explore offering in the future if in demand.)",
    },]
}

const faqDataFees = {
  title: "Fees",
  rows: [
    {
      title: "Is the amount of bitcoin estimated in the app the amount I'll receive?",
      content: <p>Yes, you can check how many sats you'll get for your fiat using the in-app price comparison widget.<br/><br/>
      It's important to note that the amount of bitcoin shown is what you would receive in your wallet if your payment clears at that particular moment in time. Normally payments clear within a few minutes (or even seconds) of when you submit your payment, however the price of bitcoin can be volatile.  There are ZERO extra fees or costs on top of this, including no transaction fees aside from a 50p surcharge for orders under £100, deducted from payments to cover our banking fees.</p>
    },
    {
      title: "What are the fees?",
      content: <p>The xsats live price excludes a ~1% fee which includes all payment, withdrawals and processing fees. The fee is included in the estimation of bitcoin/sats to receive calculated by the in app widget.<br/><br/>
      Bitcoin has no single ‘true price’ - like any other freely trading asset, its price is not decided by an oracle, it is determined by market forces. Since we acquire our bitcoin from different sources, and compare prices from major sources of liquidity in real time to find you the best exchange rate, our price is calculated at ~1% from the mid market rate.
      Through continuing to improve the efficiency of the xsats platform, and integrating other cost-saving bitcoin technologies (e.g. Lightning Network withdrawals) we aim further lower our price</p>
    },
    {
      title: "Are there any other fees?",
      content: <p>No, our price includes all fees, except for orders under £/€/CHF100 in which case there is an additional 50p/¢ fee deducted from your payment before processing. This is to pay the fixed fees imposed by our banking partners.</p>
    },]
}

const faqDataPayment = {
  title: "Payment",
  rows: [
    {
      title: "Who should I address payment to?",
      content: <p>Please ensure that the recipient for all payments is 'XSATS AG'.</p>
    },
    {
      title: "Should I use the same payment reference for all payments?",
      content: <p>Yes, your 12-digit payment reference is unique to you and should be included as reference for all bitcoin purchase orders.<br/><br/> Please ensure that your payment reference matches precisely to prevent unnecessary delays and/or processing fees.</p>
    },
    {
      title: "How can I setup an autobuy to stack sats automatically (DCA)?",
      content: <p>Simply setup a periodic standing order with your bank (e.g. daily, weekly, monthly), using your payment details provided in the xsats app, then sit back and relax as you automatically accumulate sats direct to your bitcoin wallet! Be sure to keep your wallet addresses topped up in the app to avoid address reuse and protect your privacy.<br/><br/> 
      To make stacking sats even easier, you can link your cold-storage hardware wallet (or supported software wallets) via your xpub with xsats SatLink.<a href="https://blog.xsats.com/launching-satlink-xpub-support-automatically-buy-bitcoin-direct-to-cold-storage"><strong> Learn more here.</strong></a></p>
    },
    // {
    //   title: "How can I setup an autobuy to stack sats automatically (DCA)?",
    //   content: <p>Simply setup a periodic standing order with your bank (e.g. daily, weekly, monthly), using your payment details provided in the xsats app, then sit back and relax as you automatically accumulate sats direct to your bitcoin wallet! Be sure to keep your wallet addresses topped up in the app to avoid address reuse and protect your privacy.<br/><br/> 
    //   To make stacking sats even easier, you can link your cold-storage hardware wallet (or supported software wallets) via your xpub with xsats SatLink.<a href="https://blog.xsats.com/launching-satlink-xpub-support-automatically-buy-bitcoin-direct-to-cold-storage"><strong> Learn more here.</strong></a></p>
    // },
    {
      title: "Can I buy with Euros?",
      content: <p>Working on it! We aim to launch in Europe and begin offering payments in Euros in the coming months. Please follow us on Twitter (<a href="https://twitter.com/xsatoshis"><strong>@xsatoshis</strong></a>) to stay updated.</p>
    },]
}

const faqDataAddresses = {
  title: "Receiving Bitcoin",
  rows: [
    {
      title: "Which wallets are supported?",
      content: <p>All bitcoin wallets are supported, including all different address types (segwit, native-segwit and legacy). <br/><br/> If you intend to buy and store more than ~£1k of bitcoin, we recommend using a hardware wallet and/or dedicated offline laptop to hodl chill your bitcoin for the long term, along with a carefully backed-up seed phrase. Redundancy is key with bitcoin, to prevent loss of funds.</p>
    },
    {
      title: "In which order will my bitcoin addresses receive bitcoin?",
      content: <p>Your bitcoin addresses will receive bitcoin in the order that you submit them in the app. Provided you keep your 'unused addresses' list topped up (either manually or via <a href="https://blog.xsats.com/launching-satlink-xpub-support-automatically-buy-bitcoin-direct-to-cold-storage"><strong> SatLink xpub support</strong></a> </p>
    },
    {
      title: "What if I forget to add new addresses?",
      content: <p>If, when we receive your payment, we run out of unused addresses associated with you, then your bitcoin will be sent to the address at which you most recently received bitcoin.</p>
    },
    {
      title: "What happens if I make two bitcoin purchases in quick succession? Will I receive the bitcoin all at once?",
      content: <p>If you send a payment to purchase bitcoin, then send another payment shortly after to stack even more sats (we feel you!) before your previous order has been fully processed (i.e. the bitcoin from your first purchase is still queued within a batch, and has not yet been broadcast to the network in a transaction) then the bitcoin from each order will be merged into a single output and sent to the next address in your queue.</p>
    },
    {
      title: "My wallet still shows my bitcoin as 'unconfirmed'/'pending'. What's the deal?",
      content: <p>When bitcoin transactions are sent (e.g. when we broadcast a transaction to send you your bitcoin), a fee is added to entice miners to select this transaction over others. The higher the fee, the more appealing the transaction is to miners and the higher the likelihood of the transaction being included in the next block. A transaction is only considered 'confirmed' once it has been included in at least one block.<br/><br/>
      At xsats we take a low time-preference approach to withdrawals in that we use relatively low network fees, and so it may be a bit longer until you can spend your bitcoin bought through xsats. We take this approach since our serrvice is optimised for people seeking long terrm exposure to bitcoin, and who are thus in general prrefer to get more sats instead of faster confirmation. In turn this makes xsats the most cost-effective way to buy bitcoin to self-custody!</p>
    },]
}

const styles = {
    // bgColor: 'white',
    titleTextColor: "#FFFFFF",
    rowTitleColor: "#FF7300",
    bgColor: '#000000',
    arrowColor: "#FFFFFF",
    rowContentColor: '#FFFFFF',
    // arrowColor: "red",
};

export default class FaqComponent extends Component {
    render() {
      return (
        <div className="container mt-96">
          <div className="mb-32">
            <Faq data={faqData} styles={styles}/>
          </div>
          <div className="mb-32">
            <Faq data={faqDataFees} styles={styles}/>
          </div>
          <div className="mb-32">
            <Faq data={faqDataPayment} styles={styles}/>
          </div>
          <div className="mb-32">
            <Faq data={faqDataAddresses} styles={styles}/>
          </div>
        </div>
      )
    }
  }