import React, { useRef, useEffect } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Privacy from './views/Privacy';
import FaqComponent from './components/faq/Faq';
import AppRedirectComponent from './utils/AppLink';
import ColdsatsComponent from './components/coldsats/Coldsats'

import whitepaper from './assets/documents/bitcoin-whitepaper-satoshi-nakamoto.pdf'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <Route exact path="/whitepaper">
            <Redirect to={whitepaper} />
          </Route>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/privacy" component={Privacy} layout={LayoutDefault} />
          <AppRoute exact path="/faq" component={FaqComponent} layout={LayoutDefault} />
          <AppRoute exact path="/cold" component={ColdsatsComponent} layout={LayoutDefault} />
          <AppRoute exact path="/app" component={AppRedirectComponent} layout={LayoutDefault} />
          {/* <AppRoute exact path="/restart" component={() => {window.location.href = 'https://app.xsats.com/restart'; return null;}} />
          <AppRoute exact path="/start" component={() => {window.location.href = 'https://app.xsats.com/x/'; return null;}} />
          <AppRoute exact path="/x" component={() => {window.location.href = 'https://app.xsats.com/x/'; return null;}} />
          <AppRoute path="/x/:xtag" component={(props) => {window.location.href = `https://app.xsats.com/x/${props.match.params.xtag}`; return null;}} /> */}
        </Switch>
      )} />
  );
}

export default App;