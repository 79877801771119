import React, { useEffect, useRef, useState } from "react";
import { SyncLoader } from "react-spinners";
import {
  currencies,
  currencies1,
  currencies2,
  currenciesDict,
} from "../../utils/constants";
import "./ticker.css";

export default function PriceTicker() {
  const [activeCurrency, setActiveCurrency] = useState(currencies[0].value);

  // EUR
  const [EURprice, setEURprice] = useState("loading");
  const [EURsatsPrice, setEURsatsPrice] = useState("loading");
  const [EURdayChange, setEURdayChange] = useState(0);
  const [EURdayPercentageChange, setEURdayPercentageChange] = useState(0);
  const ws = useRef<WebSocket|null>(null);
  // CHF
  const [CHFprice, setCHFprice] = useState("loading");
  const [CHFsatsPrice, setCHFsatsPrice] = useState("loading");
  const [CHFdayChange, setCHFdayChange] = useState(0);
  const [CHFdayPercentageChange, setCHFdayPercentageChange] = useState(0);
  const wsC = useRef < WebSocket | null > (null);
  // USD
  const [USDprice, setUSDprice] = useState("loading");
  const [USDsatsPrice, setUSDsatsPrice] = useState("loading");
  const [USDdayChange, setUSDdayChange] = useState(0);
  const [USDdayPercentageChange, setUSDdayPercentageChange] = useState(0);
  const wsD = useRef < WebSocket | null > (null);
  // GBP
  const [GBPprice, setGBPprice] = useState("loading");
  const [GBPsatsPrice, setGBPsatsPrice] = useState("loading");
  const [GBPdayChange, setGBPdayChange] = useState(0);
  const [GBPdayPercentageChange, setGBPdayPercentageChange] = useState(0);
  const wsG = useRef < WebSocket | null > (null);

  useEffect(() => {
    // EUR
    ws.current = new WebSocket("wss://ws.kraken.com/");
    const krakenSubscribeEUR = {
      event: "subscribe",
      pair: [`XBT/EUR`],
      subscription: {
        name: "ticker",
      },
    };
    ws.current.onopen = () =>
      ws.current!.send(JSON.stringify(krakenSubscribeEUR));
    ws.current.onclose = () => console.log("ws closed");

    // CHF
    wsC.current = new WebSocket("wss://ws.kraken.com/");
    const krakenSubscribeCHF = {
      event: "subscribe",
      pair: [`XBT/CHF`],
      subscription: {
        name: "ticker",
      },
    };
    wsC.current.onopen = () =>
      wsC.current!.send(JSON.stringify(krakenSubscribeCHF));
    wsC.current.onclose = () => console.log("wsC closed");

    // USD
    wsD.current = new WebSocket("wss://ws.kraken.com/");
    const krakenSubscribeUSD = {
      event: "subscribe",
      pair: [`XBT/USD`],
      subscription: {
        name: "ticker",
      },
    };
    wsD.current.onopen = () =>
      wsD.current!.send(JSON.stringify(krakenSubscribeUSD));
    wsD.current.onclose = () => console.log("wsD closed");

    // GBP
    wsG.current = new WebSocket("wss://ws.kraken.com/");
    const krakenSubscribeGBP = {
      event: "subscribe",
      pair: [`XBT/GBP`],
      subscription: {
        name: "ticker",
      },
    };
    wsG.current.onopen = () =>
      wsG.current!.send(JSON.stringify(krakenSubscribeGBP));
    wsG.current.onclose = () => console.log("wsG closed");

    return () => {
      ws.current!.close();
      wsC.current!.close();
      wsG.current!.close();
      wsD.current!.close();
    };
  }, []);

  // EUR
  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e) => {
      const value = JSON.parse(e.data);
      if (value.event) {
        return;
      }

      console.log(value);

      if (value[1]) {
        var price = parseFloat(value[1].c[0]); // * (1 + PERCENTAGE_FEE / 100);
        var satsPerUnit = (1 / price) * 10 ** 8;

        var price24hAgo = parseFloat(value[1].o[1]);

        var change = price - price24hAgo;
        var percentageChange = (change / price24hAgo) * 100;

        console.log("€", price.toFixed(2));
        setEURprice(price.toFixed(2));
        setEURsatsPrice(satsPerUnit.toFixed(0));
        setEURdayChange(parseFloat(change.toFixed(2)));
        setEURdayPercentageChange(parseFloat(percentageChange.toFixed(2)));
      }
    };
  }, []);

  // CHF
  useEffect(() => {
    if (!wsC.current) return;

    wsC.current.onmessage = (e) => {
      const value = JSON.parse(e.data);
      if (value.event) {
        return;
      }

      if (value[1]) {
        var price = parseFloat(value[1].c[0]); // * (1 + PERCENTAGE_FEE / 100);
        var satsPerUnit = (1 / price) * 10 ** 8;

        var price24hAgo = parseFloat(value[1].o[1]);

        var change = price - price24hAgo;
        var percentageChange = (change / price24hAgo) * 100;

        console.log("Fr.", price.toFixed(2));
        setCHFprice(price.toFixed(2));
        setCHFsatsPrice(satsPerUnit.toFixed(0));
        setCHFdayChange(parseFloat(change.toFixed(2)));
        setCHFdayPercentageChange(parseFloat(percentageChange.toFixed(2)));
      }
    };
  }, []);

  // USD
  useEffect(() => {
    if (!wsD.current) return;

    wsD.current.onmessage = (e) => {
      const value = JSON.parse(e.data);
      if (value.event) {
        return;
      }

      if (value[1]) {
        var price = parseFloat(value[1].c[0]); // * (1 + PERCENTAGE_FEE / 100);
        var satsPerUnit = (1 / price) * 10 ** 8;

        var price24hAgo = parseFloat(value[1].o[1]);

        var change = price - price24hAgo;
        var percentageChange = (change / price24hAgo) * 100;

        console.log("$", price.toFixed(2));
        setUSDprice(price.toFixed(2));
        setUSDsatsPrice(satsPerUnit.toFixed(0));
        setUSDdayChange(parseFloat(change.toFixed(2)));
        setUSDdayPercentageChange(parseFloat(percentageChange.toFixed(2)));
      }
    };
  }, []);

  // GBP
  useEffect(() => {
    if (!wsG.current) return;

    wsG.current.onmessage = (e) => {
      const value = JSON.parse(e.data);
      if (value.event) {
        return;
      }

      if (value[1]) {
        var price = parseFloat(value[1].c[0]); // * (1 + PERCENTAGE_FEE / 100);
        var satsPerUnit = (1 / price) * 10 ** 8;

        var price24hAgo = parseFloat(value[1].o[1]);

        var change = price - price24hAgo;
        var percentageChange = (change / price24hAgo) * 100;

        console.log("$", price.toFixed(2));
        setGBPprice(price.toFixed(2));
        setGBPsatsPrice(satsPerUnit.toFixed(0));
        setGBPdayChange(parseFloat(change.toFixed(2)));
        setGBPdayPercentageChange(parseFloat(percentageChange.toFixed(2)));
      }
    };
  }, []);

  let value;
  let satsValue;
  let priceChange = 0;
  let percentageChange = 0;
  if (activeCurrency === "EUR") {
    value = EURprice;
    satsValue = EURsatsPrice;
    priceChange = EURdayChange;
    percentageChange = EURdayPercentageChange;
  } else if (activeCurrency === "CHF") {
    value = CHFprice;
    satsValue = CHFsatsPrice;
    priceChange = CHFdayChange;
    percentageChange = CHFdayPercentageChange;
  } else if (activeCurrency === "USD") {
    value = USDprice;
    satsValue = USDsatsPrice;
    priceChange = USDdayChange;
    percentageChange = USDdayPercentageChange;
  } else {
    value = GBPprice;
    satsValue = GBPsatsPrice;
    priceChange = GBPdayChange;
    percentageChange = GBPdayPercentageChange;
  }

  return (
    <div className="price-ticker">
      <div className="chart-actions">
        {currencies1.map((currency) => (
          <button
            key={currency.value}
            className={`${currency.value === activeCurrency ? "active" : "inactive"
              }`}
            onClick={() => setActiveCurrency(currency.value)}
          >
            {currency.label}
          </button>
        ))}
      </div>
      <div className="chart-actions">
        {currencies2.map((currency) => (
          <button
            key={currency.value}
            className={`${currency.value === activeCurrency ? "active" : "inactive"
              }`}
            onClick={() => setActiveCurrency(currency.value)}
          >
            {currency.label}
          </button>
        ))}
      </div>
      {value === "loading" ? (
        <div className="stat spinner">
          <SyncLoader color="#fff" />
        </div>
      ) : (
        <>
          <div className="stat">
            <h4 className="label-text">Live Bitcoin Price</h4>
            <div className="price-and-change">
              <div className="live-price">
                <div className="currency-symbol">
                  {currenciesDict[activeCurrency].symbol}
                  &nbsp;
                </div>
                <div className="amount">
                  {value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </div>
              </div>
              <div
                className={`price-change ${percentageChange > 0 ? "positive-change" : "negative-change"
                  }`}
              >
                <div className="plus-minus">
                  {percentageChange > 0 ? "+" : ""}
                </div>
                <div className="change-amount">{priceChange}&nbsp;</div>
                <div className="left-bracket">(</div>
                <div className="change-percent">{percentageChange}</div>
                <div className="percent-sign">%</div>
                <div className="right-bracket">)</div>
              </div>
            </div>
          </div>
          <div className="stat">
            <h4 className="label-text">sats/{currenciesDict[activeCurrency].symbol}</h4>
            <div className="price-and-change">
              <div className="live-price">
                <div className="amount">
                  {satsValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </div>
              </div>
              <div className={`price-change`}>
                <div>Moscow Time</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
