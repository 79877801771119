import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

import twitter from "../../assets/images/social-icons/twitter-icon.png"

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const ColdsatsHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className="hero-figure reveal-from-top illustration-element-01" data-reveal-value="20px" data-reveal-delay="300">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/coldsats/coldsats-orange-bitcoin-logo@0.5x.png')}
                alt="Hero"
                width={996}
                height={704}
                opacity="10%" />
            <p className="m-0 mb-32 mt-32 reveal-from-top" data-reveal-delay="100">
                <span className="text-color-light">Secure cold storage solutions for your bitcoin.</span> 
              </p>
          </div>
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-top pb-32" data-reveal-delay="700">
              Immortalise your <span className="text-color-primary">bitcoin</span>.
            </h1>
            <div className="hero-figure reveal-from-top image-group" data-reveal-value="20px" data-reveal-delay="500" >
              <Image
                className="c-public-footer__app-icon"
                src={require('./../../assets/images/coldsats/coldsats-small-plate-front-brushed-steel@1x.png')}
                alt="coldplate-front"
                width={"30%"} />
              <Image
                src={require('./../../assets/images/coldsats/coldplate-orange-bitcoin-logo-white@0.25x.png')}
                alt="coldplate-front"
                width={"22%"} />
              <Image
                className="c-public-footer__app-icon"
                src={require('./../../assets/images/coldsats/coldsats-plate-small-back.jpg')}
                alt="coldplate-back"
                width={"30%"} />
          </div>
        </div>
      </div>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="container-xs">
            <h3 className=" reveal-from-top pb-32" data-reveal-delay="100">
              Complexity is the enemy of security <p className='text-color-light'>...but simplicity is our ally.</p>
            </h3>
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-light">High quality 304 or 316 marine-grade steel.<br /> 
                Compact design - 24 word seed-phrase on just a 40 x 95 mm plate.<br />
                Sturdy 2mm thick, double-sided etch.</span> 
            </p>
          </div>
        </div>
        <div className="hero-content">
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="400">
            Secure your stack.
          </h1>
        </div>
      </div>
    </div>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span className="text-color-primary">Coming Soon!</span>
            </h1>
            <div>
            <a  href="https://twitter.com/coldsats" style={{color: "#FFF"}} target="_blank" rel = "noopener noreferrer" data-reveal-delay="200">
              <p >Follow for updates  </p>
                <img className="c-public-footer__social-icon" alt="Twitter icon" src={twitter} target="_blank" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ColdsatsHero.propTypes = propTypes;
ColdsatsHero.defaultProps = defaultProps;

export default ColdsatsHero;