import React, { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import Spinner from "react-bootstrap/Spinner";
import CoinGecko from "coingecko-api";
import "./HistoricChart.css";

const CoinGeckoClient = new CoinGecko();

const HistoricChart = () => {
  const [data, setData] = useState([]);
  const [series, setSeries] = useState("7");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistoricalData("182");
  }, []);

  // function gets historical btc price data
  async function getHistoricalData(series) {
    try {
      setLoading(true);

      // set series state
      setSeries(series);

      // get historical data for bitcoin based on series length
      const { data } = await CoinGeckoClient.coins.fetchMarketChart("bitcoin", {
        days: series,
        vs_currency: 'gbp'
      });

      // format the data, and calculate necessary totals
      let prices = [];
      data.prices.forEach((price) =>
        prices.push({ price: price[1], timestamp: price[0] })
      );

      setData(prices);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  if (!loading) {
    return (
      <div className="HistoricChart">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} margin={{ left: 10 }}>
            <Tooltip labelStyle={{color: "#CCC"}} style={{ opacity: 0}}
              formatter={(value, name) => {
                name = "Price";

                value = `£${value
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

                return [value, name];
              }}
              labelFormatter={(timestamp) => {
                timestamp = new Date(timestamp);
                timestamp = timestamp.toLocaleDateString();

                return timestamp;
              }}
            />

            <XAxis dataKey="timestamp" hide />

            <YAxis
              tickFormatter={(price) => {
                return (
                  "£" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }}
            />

            <Area
              type="monotone"
              dataKey="price"
              stroke="#000"
              fill="#ff9900"
            />
          </AreaChart>
        </ResponsiveContainer>

        <div
          style={{
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          <ul>
            <li
              className="seriesLabel"
              style={series === "7" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("7")}
            >
              7d
            </li>

            <li
              className="seriesLabel"
              style={series === "30" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("30")}
            >
              1m
            </li>

            <li
              className="seriesLabel"
              style={series === "182" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("182")}
            >
              6m
            </li>

            <li
              className="seriesLabel"
              style={series === "365" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("365")}
            >
              1y
            </li>

            <li
              className="seriesLabel"
              style={series === "730" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("730")}
            >
              2y
            </li>

            <li
              className="seriesLabel"
              style={series === "1095" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("1095")}
            >
              3y
            </li>

            <li
              className="seriesLabel"
              style={series === "1825" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("1825")}
            >
              5y
            </li>

            <li
              className="seriesLabel"
              style={series === "max" ? { fontWeight: "bold" } : null}
              onClick={() => getHistoricalData("max")}
            >
              Max
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinnerContainer">
        <Spinner className="spinner" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
};

export default HistoricChart;
