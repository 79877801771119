import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  // const sectionHeader = {
  //   title: 'Be your own bank.',
  //   paragraph: 'Bitcoin enables financial independence in a way that was previously not possible. We\'re building xsats to help you take advantage of the properties of bitcoin that make it so revolutionary.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/fast-clock-icon@0.5x.png')}
                      alt="Features tile icon 01"
                      width={104}
                      height={104} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Buy in Minutes
                    </h4>
                  <p className="m-0 text-sm">
                    Begin saving bitcoin via the xsats mobile or web app within minutes. No lengthy registration process.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/bitcoin_PNG47@1x.png')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Bitcoin Only
                    </h4>
                  <p className="m-0 text-sm">
                    We are 100% dedicated towards building Bitcoin-native financial services. Bitcoin is the future of money; 'crypto' is noise.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/bitcoin-wallet-icon@0.5x.png')}
                      alt="Features tile icon 03"
                      width={94}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Self Custodial
                    </h4>
                  <p className="m-0 text-sm">
                    Your keys, your bitcoin. Receive your bitcoin directly in your personal wallet, whether it be a cold-storage hardware wallet or your mobile wallet of choice.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/hardware-wallet@0.5x.png')}
                      alt="Features tile icon 04"
                      width={54}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Hardware Wallet Support
                    </h4>
                  <p className="m-0 text-sm">
                    Link your hardware wallet to automatically manage your bitcoin receiving addresses. Your xpubs never touch our servers and remain encrypted on your phone.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/bitcoin-stack-sats-icon@0.25x.png')}
                      alt="Features tile icon 02"
                      width={74}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    #StackingSats Simplified
                    </h4>
                  <p className="m-0 text-sm">
                  Buy the dips, set up automatic periodic purchases or FOMO buy at your convenience, always directly to self-custody!
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/square-hash-icon@0.5x.png')}
                      alt="Features tile icon 06"
                      width={84}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Security First
                    </h4>
                  <p className="m-0 text-sm">
                    With great power comes great responsibility. Your security is our priority, and what drives the development of xsats.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/gears-icon@1x.png')}
                      alt="Features tile icon 06"
                      width={84}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Free Automated Withdrawals
                    </h4>
                  <p className="m-0 text-sm">
                    Other services charge upto 5% fees and often over £15 to release <i>your</i> bitcoin. With xsats, withdrawal fees are included in our 1% fee. <br/> Own bitcoin, not IOUs. 
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;