// const currencies = ["£", "€", "$", " sats", "₿"];
export const currenciesDict = {
  CHF: {
    symbol: "Fr",
  },
  GBP: {
    symbol: "£",
  },
  EUR: {
    symbol: "€",
  },
  USD: {
    symbol: "$",
  },
};

export const currencies = [
  {
    label: "€",
    value: "EUR",
  },
  {
    label: "Fr",
    value: "CHF",
  },
  {
    label: "£",
    value: "GBP",
  },
  {
    label: "$",
    value: "USD",
  },
];

export const currencies1 = [
  {
    label: "€",
    value: "EUR",
  },
  {
    label: "Fr",
    value: "CHF",
  },
];

export const currencies2 = [
  {
    label: "£",
    value: "GBP",
  },
  {
    label: "$",
    value: "USD",
  },
];

export const currencyPairs = ["XBT/GBP", "XBT/EUR", "XBT/USD", "XBT/CHF"];
